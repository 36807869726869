import classNames from 'classnames'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { RichText } from 'prismic-reactjs'
import React from 'react'
import { Container } from 'react-bootstrap'
import {
  BbvPageProps,
  CustomPageDataRawSlices,
  EmbedSliceItem,
  PrismicCustomPageProps,
} from 'src/types'
import Layout from '../components/layout'
import * as styles from './custom-page.module.scss'

export const query = graphql`
  query CustomPageQuery($uid: String!) {
    prismicCustomPage(uid: { eq: $uid }) {
      data {
        title {
          raw
          text
        }
        subtitle
        content {
          raw
        }
      }
      dataRaw
    }
    prismicSettings {
      ...settings
    }
    prismicLinks {
      ...links
    }
  }
`

const Embed = ({ item }: { item: EmbedSliceItem }) => {
  return (
    <div
      className={classNames(styles.slice, {
        [styles.youtubeVideo]: item.embed.embed_url.startsWith('https://www.youtube.com'),
      })}
      dangerouslySetInnerHTML={{ __html: item.embed.html }}
    />
  )
}

const Slice = ({ data }: { data: CustomPageDataRawSlices }) => {
  switch (data.slice_type) {
    case 'embed':
      return (
        <>
          {data.items.map((item, i) => (
            <Embed key={i} item={item} />
          ))}
        </>
      )
    case 'html':
      return (
        <div
          className={styles.slice}
          dangerouslySetInnerHTML={{ __html: data.primary.embed_code }}
        />
      )
    case 'rich_text':
      return (
        <RichText
          render={
            Array.isArray(data.primary.rich_text)
              ? data.primary.rich_text
              : [data.primary.rich_text]
          }
        />
      )
    default:
      return null
  }
}

const CustomPage = (props: BbvPageProps<PrismicCustomPageProps>) => {
  const { data, dataRaw } = props.data.prismicCustomPage
  return (
    <Layout title={data.title.text} pageData={props.data}>
      <div className="page-container">
        <Container>
          <div className={styles.header}>
            <RichText render={data.title.raw} />
            {data.subtitle && <div className={styles.subtitle}>{data.subtitle}</div>}
          </div>
          {dataRaw.body.map((slice, i) => (
            <Slice data={slice} key={i} />
          ))}
        </Container>
      </div>
    </Layout>
  )
}

export default withPreview(CustomPage)
